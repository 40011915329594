import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-modal-footer',
    templateUrl: './modal-footer.component.html',
    styleUrls: [ './modal-footer.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ModalFooterComponent {
}
